import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/lynaza-logo-color.svg"
import "../css/common.css"
import "../css/header.css"

const Header = ({ siteTitle }) => (
  <header
    className="header-section"
  >
    <div >
      <div className="logo">
        <Link
          to="/"
        >
          {/* {siteTitle} */}
          <img src={logo} alt=""/>
        </Link>
      </div>
      <div className="nav">
        <Link className="nav-item" to="/lawDNA">
          Law DNA
        </Link>
        <Link className="nav-item" to="/cirrus">
          Cirrus
        </Link>
        <Link className="nav-item" to="/sanction">
          Sanction
        </Link>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
