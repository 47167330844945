/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import map from "../images/map-marker-alt-solid.svg"
import email from "../images/envelope-solid.svg"
import phone from "../images/phone-solid.svg"
import "../css/common.css"
import "../css/main.css"
import "./layout.css"
import "../css/footer.css"

const Layout = ({ children }) => {
  return (
    <>
      <Header siteTitle={`Lynaza`} />
      <div>
        <main className="main-section">
          {children}
        </main>
        <footer className="footer-section">
          <div className="section">
            <div className="introduction">
              <div className="title">立捷數據整合</div>
              <ul>
                <li>
                  <img src={map} alt="立捷數據整合"/>
                  <a href="https://goo.gl/maps/tKJoqP8knKdzBVbe7">高雄市新興區中正三路 25 號 14 樓（高雄智慧科技創新園區）</a>
                </li>
                <li>
                  <img src={email} alt="聯絡立捷數據整合"/>
                  nlin@eDiscovery.com.tw
                </li>
                <li>
                  <img src={phone} alt="聯絡立捷數據整合"/>
                  <a href="tel:072222700">+(886)-7-222-2700</a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
